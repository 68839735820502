import React from "react";

import { Container, Typography } from "@material-ui/core";

const NotFound = () => {
    return (
        <>
            <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h6">404 | Página não encontrada</Typography>
            </Container>
        </>
    );
}

export default NotFound;