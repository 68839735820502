import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NotFound from './pages/erros/notFound';
import Home from './pages/home';
import Login from './pages/login';
import Admin from './pages/admin';
import Reserv from './pages/reserv';
import RecoveryPassword from './pages/recoveryPassword';

ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/reservar" exact={true} component={Reserv} />
        <Route path="/recuperar-senha/:id" component={RecoveryPassword} />
        <Route path="/recuperar-senha" component={RecoveryPassword} />
        <Route path="/login" component={Login} />
        <Route path="/admin" component={Admin} />
        <Route path='*' component={NotFound} />
      </Switch>
    </ BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
