import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

import prototype from '../assets/img/prototype_version_3.png';

import Menu from '../components/menu.js';

const useStyles = makeStyles({
    img: {
        width: '80%',
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto'
    }
});

const Home = () => {

    const classes = useStyles();

    return (
        <>
            
            <Menu />

            <Container style={{ minHeight: '88vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                    <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>Não desperdice frutas</Typography>
                    <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '70px', color: '#707072' }}>Te ajudamos a monitorá-las</Typography>
                    <img src={prototype} alt="Smartfruit" className={classes.img} />
                </div>
            </Container>

            <Container maxWidth="xl" style={{ backgroundColor: '#F5C144' }} >
                <Container style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                    <Typography variant="h4" style={{ color: '#FFF', fontWeight: 'lighter' }}>Sobre</Typography>
                    <br/>
                    <Typography variant="h6" style={{ color: 'gray', fontWeight: 'lighter' }}>A Smart Fruit é uma fruteira inteligente capaz de evitar que haja o desperdício de frutas dentro de sua casa, monitorando em tempo real dados como luminosidade, temperatura e umidade.</Typography>
                </Container>
            </Container>

            <Container maxWidth="xl" style={{ backgroundColor: '#FFF' }} >
                <Container style={{ paddingTop: '200px', paddingBottom: '200px' }}>
                    <Typography variant="h4" style={{ color: 'gray', fontWeight: 'lighter' }}>Objetivo</Typography>
                    <br/>
                    <Typography variant="h6" style={{ color: 'gray', fontWeight: 'lighter' }}>No mundo todo existem inúmeras pessoas que passam fome. Segundo a ONU este número pode chegar a mais de 800 milhões. Além da fome, outro número assustador é o de desperdício de alimentos no mundo que chega a mais de 1 bilhão e 300 milhões de toneladas anuais. Baseado nestas informações queremos fazer a diferença, começando dentro de nossas casas.</Typography>
                </Container>
            </Container>
            
        </>
    );
}

export default Home;