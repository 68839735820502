import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Hidden, CircularProgress } from '@material-ui/core';
import logo from '../assets/img/logo.png';
import { Link, useHistory } from "react-router-dom";
import API from '../api';
import RoomServiceRoundedIcon from '@material-ui/icons/RoomServiceRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

const useStyles = makeStyles({
    appBar: {
      backgroundColor: '#FFF',
      boxShadow: '0px 4px 25px rgb(25 25 25 / 20%)',
      paddingTop: '20px',
      paddingBottom: '20px'
    },
    title: {
        flexGrow: 1
    },
    logo: {
        width: '60px',
        cursor: 'pointer'
    },
    button: {
        backgroundColor: '#ffc107',
        color: '#FFF',
        textTransform: 'none',
        padding: '10px 20px 10px 20px',
        borderRadius: '8px'
    },
    buttonOutlined: {
        border: '1px solid #ffc107',
        color: '#ffc107',
        textTransform: 'none',
        padding: '10px 20px 10px 20px',
        borderRadius: '8px'
    }
});

const Menu = props => {

    const history = useHistory();
    const classes = useStyles();

    const handleLogout = () => {

        setIsLoading(true);

        API.post('/logout' , {})
        .then(res => {
            setIsLoading(false);
            localStorage.removeItem('sessionToken');
            history.push('/');
        })
        .catch(err => {
            setIsLoading(false);
        })

    }

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    {props.logged ?
                        <img onClick={() => {props.homeDash()}} src={logo} alt="Smartfruit" className={classes.logo} />
                    :
                        <Link to='/'><img src={logo} alt="Smartfruit" className={classes.logo} /></Link>
                    }
                    <Typography variant="h6" className={classes.title}></Typography>
                    {props.logged ?
                        <Button onClick={handleLogout} color="inherit" className={classes.button}>
                            {isLoading ? 
                                <CircularProgress size={24} color="#FFF" />
                            :
                                'Sair'
                            }
                        </Button>
                    :
                        <>
                            <Link to="/reservar" style={{ textDecoration: 'none' }}>
                                <Button color="inherit" className={classes.buttonOutlined} style={{marginRight: '10px'}}>
                                    <Hidden smUp>
                                        <RoomServiceRoundedIcon />
                                    </Hidden>
                                    <Hidden smDown>
                                        <RoomServiceRoundedIcon /> &nbsp; Reservar fruteira
                                    </Hidden>
                                </Button>
                            </Link>
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <Button color="inherit" className={classes.button}>
                                    <Hidden smUp>
                                        <HomeRoundedIcon />
                                    </Hidden>
                                    <Hidden smDown>
                                        <HomeRoundedIcon /> &nbsp; Acessar fruteira
                                    </Hidden>
                                </Button>
                            </Link>
                        </>
                    }
                </Toolbar>
            </AppBar>
            
        </>
    );
}

export default Menu;