import axios from 'axios';

axios.defaults.headers.common = {
  'Authorization': 'Bearer ' + localStorage.getItem('sessionToken')
};

const location = window.location;

let url = "http://api.smartfruit.com.br/";

if(location.hostname == 'localhost'){
    url = process.env.REACT_APP_API_LOCAL;
}else{
    url = process.env.REACT_APP_API_PROD;
}

export default axios.create({
  baseURL: url
});