import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Container, Button, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Menu from '../components/menu.js';
import { useHistory } from "react-router-dom";
import API from '../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#ffc107',
        color: '#FFF',
        textTransform: 'none',
        borderRadius: '8px'
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
        }
    },
    div: {
        width: '40%',
        '@media (max-width: 779px)': {
            width: '90%'
        }
    }
});

const Login = () => {

    const history = useHistory();
    const classes = useStyles();

    const [serialNumber, setSerialNumber] = useState();
    const [password, setPassword] = useState();

    const [errorSerialNumber, setErrorSerialNumber] = useState();
    const [errorPassword, setErrorPassword] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = () => {

        setIsLoading(true);

        if(!serialNumber){
            setErrorSerialNumber(true)
            setIsLoading(false);
        }else{
            setErrorSerialNumber(false)
        }

        if(!password){
            setErrorPassword(true)
            setIsLoading(false);
        }else{
            setErrorPassword(false)
        }

        if(serialNumber && password){

            API.post(`/login`, {
                serial_number: serialNumber,
                password: password
            })
            .then(res => {
                localStorage.setItem('sessionToken', res.data.token);
                history.push('/admin');
                setIsLoading(false);
            })
            .catch(err => {
                setErrorSerialNumber(true)
                setErrorPassword(true)
                setIsLoading(false);
                setSeveritySnackbar('error');
                setMessageSnackbar('Dados inválidos!');
                setOpenSnackbar(true);
            })

        }

    }

    const handleRecoverPassword = () => {

        setIsLoading(true);

        if(!serialNumber){
            setErrorSerialNumber(true)
            setIsLoading(false);
        }else{
            setErrorSerialNumber(false)
        }

        if(serialNumber){

            API.post(`/recover-password`, {
                serial_number: serialNumber
            })
            .then(res => {
                setIsLoading(false);
                setSuccessRecoveryPassword(true);
                setEmailMask(res.data.email);
            })
            .catch(err => {
                setErrorSerialNumber(true)
                setErrorPassword(true)
                setIsLoading(false);
                setSeveritySnackbar('error');
                setMessageSnackbar('Equipamento não encontrado');
                setOpenSnackbar(true);
            })

        }

    }

    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [severitySnackbar, setSeveritySnackbar] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [fogortPassword, setFogortPassword] = useState(false);

    function onlyNumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        //var regex = /^[0-9.,]+$/;
        var regex = /^[0-9.]+$/;
        if( !regex.test(key) ) {
           theEvent.returnValue = false;
           if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    const [successRecoveryPassword, setSuccessRecoveryPassword] = useState(false);
    const [emailMask, setEmailMask] = useState();

    return (
        <>

            <Menu />

            <Container style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={classes.div}>

                    {!fogortPassword ?
                        <>
                            <Typography variant="h5">Acesse os dados da sua fruteira</Typography>
                            <br/><br/>
                            <TextField error={errorSerialNumber} value={serialNumber} onChange={(e) => {setSerialNumber(e.target.value.replace(/\D/g, '')); setErrorSerialNumber(false);}} className={classes.textField} id="outlined-basic" label="Número de série" variant="outlined" fullWidth />
                            <br/><br/>
                            <TextField error={errorPassword} value={password} onChange={(e) => {setPassword(e.target.value); setErrorPassword(false);}} className={classes.textField} id="outlined-basic" label="Senha" variant="outlined" type="password" fullWidth />
                            <br/><br/>
                            <Button disabled={!serialNumber || !password} onClick={handleLogin} size="large" fullWidth color="inherit" className={classes.button}>
                                {isLoading ? 
                                    <CircularProgress size={24} color="#FFF" />
                                :
                                    'Acessar'
                                }
                            </Button>
                            <br/><br/>
                            <Typography onClick={() => {setFogortPassword(true)}} variant="body1" style={{ textAlign: 'center', color: '#707072', cursor: 'pointer' }}>Esqueci minha senha</Typography>
                        </>
                    :
                        <>
                            {successRecoveryPassword ?
                                <>
                                    <Typography variant="h5">Recupere sua senha</Typography>
                                    <br/><br/>
                                    <Typography variant="body1">E-mail de recuperação enviado para <b>{emailMask}</b></Typography>
                                    <br/><br/>
                                    <Button onClick={() => {setFogortPassword(false); setSuccessRecoveryPassword(false)}} size="large" fullWidth color="inherit" className={classes.button}>Fazer login</Button>
                                </>
                            :
                                <>
                                    <Typography variant="h5">Recupere sua senha</Typography>
                                    <br/><br/>
                                    <TextField error={errorSerialNumber} value={serialNumber} onChange={(e) => {setSerialNumber(e.target.value.replace(/\D/g, '')); setErrorSerialNumber(false);}} className={classes.textField} id="outlined-basic" label="Número de série" variant="outlined" fullWidth />
                                    <br/><br/>
                                    <Button disabled={!serialNumber} onClick={handleRecoverPassword} size="large" fullWidth color="inherit" className={classes.button}>
                                        {isLoading ? 
                                            <CircularProgress size={24} color="#FFF" />
                                        :
                                            'Recuperar'
                                        }
                                    </Button>
                                    <br/><br/>
                                    <Typography onClick={() => {setFogortPassword(false)}} variant="body1" style={{ textAlign: 'center', color: '#707072', cursor: 'pointer' }}>Fazer login</Typography>
                                </>
                            }
                        </>
                    }
                </div>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

        </>
    );
}

export default Login;