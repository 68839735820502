import React, { useState } from "react";
import Menu from '../components/menu';
import { Container, Typography, TextField, Button, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import API from '../api';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import InputMask from 'react-input-mask';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#ffc107',
        color: '#FFF',
        textTransform: 'none',
        borderRadius: '8px'
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
        }
    },
    div: {
        width: '40%',
        '@media (max-width: 779px)': {
            width: '90%'
        }
    }
});

const Reserv = () => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    const [errorName, setErrorName] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();

    const handleReserv = () => {

        setIsLoading(true);

        if(!name){
            setErrorName(true);
        }else{
            setErrorName(false);
        }

        if(!email){
            setErrorEmail(true);
        }else{
            setErrorEmail(false);
        }

        if(!phone){
            setErrorPhone(true);
        }else{
            setErrorPhone(false);
        }

        if(name && email && phone){

            API.post('/reserv' , {
                name: name,
                email: email,
                phone: phone
            })
            .then(res => {
                setName('');
                setEmail('');
                setPhone('');
                setIsLoading(false);
                setSeveritySnackbar('success');
                setMessageSnackbar('Reserva realizada com sucesso!');
                setOpenSnackbar(true);
            })
            .catch(err => {
                setIsLoading(false);
                setSeveritySnackbar('error');
                setMessageSnackbar('Tivemos um erro ao fazer a reserva. Tente novamente!');
                setOpenSnackbar(true);
            })

        }else{

            setIsLoading(false);

        }

    }

    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [severitySnackbar, setSeveritySnackbar] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    return (
        <>

            <Menu />

            <Container style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={classes.div}>
                    <Typography variant="h5">Reserve sua fruteira inteligente</Typography>
                    <Typography variant="body1">Estamos quase prontos para começar a produzir em larga escala!</Typography>
                    <br />
                    <Typography variant="body1" style={{ display: 'flex' }}><MonetizationOnOutlinedIcon /> &nbsp; Preço: Será justo</Typography>
                    <Typography variant="body1" style={{ display: 'flex', marginTop: '5px' }}><AccessTimeOutlinedIcon /> &nbsp; Previsão: Primeiro semestre de 2022</Typography>
                    <br/><br/>
                    <TextField 
                        error={errorName} 
                        value={name} 
                        onChange={(e) => {
                            setName(e.target.value); 
                            setErrorName(false);
                        }} 
                        className={classes.textField} 
                        id="outlined-basic" l
                        label="Nome" 
                        variant="outlined" 
                        fullWidth 
                        InputLabelProps={{
                            shrink: name ? true : false,
                        }}
                    />
                    <br/><br/>
                    <InputMask maskChar="" mask="(99) 99999-9999" value={phone} onChange={(e) => {setPhone(e.target.value); setErrorPhone(false);}}>
                        {(inputProps) => <TextField InputLabelProps={{
                            shrink: phone ? true : false,
                        }} error={errorPhone} className={classes.textField} id="outlined-basic" label="Telefone" variant="outlined" fullWidth />}
                    </InputMask>
                    <br/><br/>
                    <TextField 
                        error={errorEmail} 
                        value={email} 
                        onChange={(e) => {
                            setEmail(e.target.value); 
                            setErrorEmail(false);
                        }} 
                        className={classes.textField} 
                        id="outlined-basic" 
                        label="E-mail" 
                        variant="outlined" 
                        fullWidth 
                        InputLabelProps={{
                            shrink: email ? true : false,
                        }}
                    />
                    <br/><br/>
                    <Button onClick={handleReserv} size="large" fullWidth color="inherit" className={classes.button}>
                        {isLoading ? 
                            <CircularProgress size={24} color="#FFF" />
                        :
                            'Reservar'
                        }
                    </Button>
                </div>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            
        </>
    );
}

export default Reserv;