import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography, Button, Chip, Modal, Fade, Backdrop, TextField, Card, Checkbox, Radio, RadioGroup, CircularProgress, Hidden } from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Menu from '../components/menu.js';

import bananaStage1 from '../assets/img/stagesFruits/banana/1.png';
import bananaStage2 from '../assets/img/stagesFruits/banana/3.png';
import bananaStage3 from '../assets/img/stagesFruits/banana/3.png';
import bananaStage4 from '../assets/img/stagesFruits/banana/4.png';
import bananaStage5 from '../assets/img/stagesFruits/banana/5.png';
import bananaStage6 from '../assets/img/stagesFruits/banana/6.png';
import bananaStage7 from '../assets/img/stagesFruits/banana/7.png';

import macaStage1 from '../assets/img/stagesFruits/maca/1.png';
import macaStage2 from '../assets/img/stagesFruits/maca/2.png';
import macaStage3 from '../assets/img/stagesFruits/maca/3.png';
import macaStage4 from '../assets/img/stagesFruits/maca/4.png';
import macaStage5 from '../assets/img/stagesFruits/maca/5.png';

import mangaStage1 from '../assets/img/stagesFruits/manga/1.png';
import mangaStage2 from '../assets/img/stagesFruits/manga/2.png';
import mangaStage3 from '../assets/img/stagesFruits/manga/3.png';
import mangaStage4 from '../assets/img/stagesFruits/manga/4.png';
import mangaStage5 from '../assets/img/stagesFruits/manga/5.png';

import tomateStage1 from '../assets/img/stagesFruits/tomate/1.png';
import tomateStage2 from '../assets/img/stagesFruits/tomate/2.png';
import tomateStage3 from '../assets/img/stagesFruits/tomate/3.png';
import tomateStage4 from '../assets/img/stagesFruits/tomate/4.png';
import tomateStage5 from '../assets/img/stagesFruits/tomate/5.png';

import { Alert, AlertTitle } from '@material-ui/lab';

import API from '../api';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paperGrid: {
        boxShadow: '0px 4px 25px rgb(25 25 25 / 20%)', 
        padding: '20px', 
        borderRadius: '8px',
        border: 'none'
    },
    button: {
        backgroundColor: '#ffc107',
        color: '#FFF',
        textTransform: 'none',
        padding: '10px 20px 10px 20px',
        borderRadius: '8px'
    },
    heading: {
        display: 'flex', 
        alignItems: 'center'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        borderRadius: '8px',
        width: '60%'
    },
    paperPassword: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        borderRadius: '8px',
        width: '30%',
        '@media (max-width: 779px)': {
            width: '80%'
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    contentAccordionDetails: {
        display: 'flex',
        '@media (max-width: 779px)': {
            display: 'block'
        }
    },
    chip: {
        backgroundColor: 'green', 
        color: '#FFF', 
        marginLeft: '10px',
        cursor: 'pointer',
        '@media (max-width: 779px)': {
            marginLeft: '0px',
            marginTop: '10px'
        }
    },
    chipOutlined: {
        backgroundColor: '#FFF', 
        border: '1px solid #ffc107', 
        color: '#ffc107', 
        marginLeft: '0px',
        cursor: 'pointer',
        '@media (max-width: 779px)': {
            marginLeft: '0px',
            marginTop: '0px'
        }
    }
}));

const Admin = props => {

    const history = useHistory();
    const classes = useStyles();

    const [view, setView] = useState('fruits'); // fruits, configs, info
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [spaceFruit, setSpaceFruit] = useState();
    const [typeFruit, setTypeFruit] = useState();
    const [maturationFruit, setMaturationFruit] = useState();

    const [actualPassword, setActualPassword] = useState();
    const [newPassword, setNewPassword] = useState();

    const [openConsumir, setOpenConsumir] = useState(false);
    const [spaceConsumir, setSpaceConsumir] = useState();

    const handleOpenModalConsumir = (index) => {

        setSpaceConsumir(index);
        setOpenConsumir(true)

    }

    useEffect(() => {

        API.get('/me' , { 
            params: {},
            headers: {
                "Authorization" : `Bearer ${localStorage.getItem('sessionToken')}`
            } 
        })
        .then(res => {
            setSerailNumber(res.data.serial_number);
            setConnection(res.data.connection);
        })
        .catch(err => {
            localStorage.removeItem('token');
            history.push('/');
        })

        handleGetConfigs();
        handleGetDataFruits();

    }, [0])

    const handleGetConfigs = () => {

        API.get('/configs' , { 
            params: {},
            headers: {
                "Authorization" : `Bearer ${localStorage.getItem('sessionToken')}`
            } 
        })
        .then(res => {
            if(res.data){
                setConfigOne(res.data.configs.config_one);
                setConfigTwo(res.data.configs.config_two);
                setConfigThree(res.data.configs.config_three);
            }
        })
        .catch(err => {})

    }

    const [serailNumber, setSerailNumber] = useState();
    const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);

    const handleChangePassword = () => {

        setIsLoadingChangePassword(true);

        API.post('/change-password' , { 
            password: newPassword
        })
        .then(res => {
            setIsLoadingChangePassword(false);
            setOpenPassword(false);
            setNewPassword('');
        })
        .catch(err => {
            setIsLoadingChangePassword(false);
        })

    }

    const [configOne, setConfigOne] = useState(false);
    const [configTwo, setConfigTwo] = useState(false);
    const [configThree, setConfigThree] = useState(false);
    const [isLoadingChangeConfigs, setIsLoadingChangeConfigs] = useState(false);

    const handleUpdateConfigs = () => {

        setIsLoadingChangeConfigs(true);

        API.post('/configs/update' , { 
            config_one: configOne ? 1 : 0,
            config_two: configTwo ? 1 : 0,
            config_three: configThree ? 1 : 0
        })
        .then(res => {
            handleGetConfigs();
            setIsLoadingChangeConfigs(false);
        })
        .catch(err => {})

    };

    const [fruits, setFruits] = useState([]);
    const [fruitsAlarm, setFruitsAlarm] = useState([]);

    const handleGetDataFruits = () => {

        API.get('/data_fruits' , { 
            params: {},
            headers: {
                "Authorization" : `Bearer ${localStorage.getItem('sessionToken')}`
            } 
        })
        .then(res => {
            setFruits(res.data.data_fruit);
            setFruitsAlarm(res.data.data_fruit_alert);
        })
        .catch(err => {})

    }

    const [openAlert, setOpenAlert] = useState(false);
    const [connection, setConnection] = useState(true);

    return (
        <>

            <Menu logged={true} homeDash={() => {setView('fruits')}} />

            {fruitsAlarm.length > 0 && (
                <Container style={{ marginTop: '30px' }}>
                    <Grid spacing={3} container>
                        <Grid item md={12} xs={12}>
                        <Alert severity="warning">
                            <AlertTitle>Alerta</AlertTitle>
                            Suas frutas estão em perigo. Verifica as condições da sua fruteira.
                        </Alert>
                        </Grid>
                    </Grid>
                </Container>
            )}

            <Container style={{ marginTop: fruitsAlarm.length > 0 ? '10px' : '30px' }}>
                <Grid spacing={3} container>
                    <Grid item md={4} xs={12}>
                        <Paper elevation={3} className={classes.paperGrid}>
                            <Typography onClick={() => {setView('fruits')}} style={{ color: view === "fruits" ? '#ffc107' : '', cursor: 'pointer' }}>Frutas</Typography>
                            <br/>
                            <Typography onClick={() => {setView('configs')}} style={{ color: view === "configs" ? '#ffc107' : '', cursor: 'pointer' }}>Configurações</Typography>
                            <br/>
                            <Typography onClick={() => {setView('info')}} style={{ color: view === "info" ? '#ffc107' : '', cursor: 'pointer' }}>Informações</Typography>
                        </Paper>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Paper elevation={3} className={classes.paperGrid}>
                            {view === "fruits" && (
                                <>
                                    <Hidden smDown>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button color="inherit" className={classes.button} onClick={() => {setOpen(true)}}>
                                                Adicionar fruta
                                            </Button>
                                            <div>
                                                {connection ?
                                                    <Button color="inherit" className={classes.button} style={{ backgroundColor: 'green', marginRight: '10px' }}>
                                                        Fruteira conectada
                                                    </Button>
                                                :
                                                    <Button color="inherit" className={classes.button} style={{ backgroundColor: 'red', marginRight: '10px' }}>
                                                        Fruteira desconectada
                                                    </Button>
                                                }
                                                <Button color="inherit" className={classes.button} onClick={() => {setOpenAlert(true)}}>
                                                    Exemplo alerta
                                                </Button>
                                            </div>
                                        </div>
                                    </Hidden>
                                    <Hidden smUp>
                                        <Button fullWidth color="inherit" className={classes.button} onClick={() => {setOpen(true)}}>
                                            Adicionar fruta
                                        </Button>
                                        <br /><br />
                                        {connection ?
                                            <Button fullWidth color="inherit" className={classes.button} style={{ backgroundColor: 'green', marginRight: '10px' }}>
                                                Fruteira conectada
                                            </Button>
                                        :
                                            <Button fullWidth color="inherit" className={classes.button} style={{ backgroundColor: 'red', marginRight: '10px' }}>
                                                Fruteira desconectada
                                            </Button>
                                        }
                                        {/*<Button fullWidth color="inherit" className={classes.button} onClick={() => {setOpenAlert(true)}}>
                                            Exemplo alerta
                                        </Button>*/}
                                    </Hidden>
                                    <br/><br/>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <div className={classes.contentAccordionDetails}>
                                                <Typography className={classes.heading}>Espaço 1: Maça</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                <Typography>Maturidade inicial: <b>1 (Verde)</b></Typography><br/>
                                                <Typography>Maturidade atual: <b>4 (Madura)</b></Typography>
                                                <Typography>Temperatura atual: <b>24˚C</b></Typography>
                                                <Typography>Luminosidade atual: <b>150</b></Typography>
                                                <Typography>Umidade atual: <b>70%</b></Typography><br/>
                                                <Typography>Tempo na fruteira: <b>7 dias</b></Typography>
                                                <Typography>Média de temperatura: <b>24˚C</b></Typography>
                                                <Typography>Média de luminosidade: <b>150</b></Typography>
                                                <Typography>Média de umidade: <b>70%</b></Typography><br/>
                                                <Typography>Tempo ainda disponível para consumo: <b>10 dias</b></Typography><br/>
                                                <Chip onClick={() => {handleOpenModalConsumir(1)}} label="Consumir fruta" className={classes.chipOutlined} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <div className={classes.contentAccordionDetails}>
                                                <Typography className={classes.heading}>Espaço 2: Manga</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                <Typography>Maturidade inicial: <b>1 (Verde)</b></Typography><br/>
                                                <Typography>Maturidade atual: <b>4 (Madura)</b></Typography>
                                                <Typography>Temperatura atual: <b>24˚C</b></Typography>
                                                <Typography>Luminosidade atual: <b>150</b></Typography>
                                                <Typography>Umidade atual: <b>70%</b></Typography><br/>
                                                <Typography>Tempo na fruteira: <b>7 dias</b></Typography>
                                                <Typography>Média de temperatura: <b>24˚C</b></Typography>
                                                <Typography>Média de luminosidade: <b>150</b></Typography>
                                                <Typography>Média de umidade: <b>70%</b></Typography><br/>
                                                <Typography>Tempo ainda disponível para consumo: <b>10 dias</b></Typography><br/>
                                                <Chip onClick={() => {handleOpenModalConsumir(2)}} label="Consumir fruta" className={classes.chipOutlined} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <div className={classes.contentAccordionDetails}>
                                                <Typography className={classes.heading}>Espaço 3: Sem fruta</Typography>
                                            </div>
                                        </AccordionSummary>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <div className={classes.contentAccordionDetails}>
                                                <Typography className={classes.heading}>Espaço 4: Tomate</Typography>
                                                <Chip label="Pronta para consumo" className={classes.chip} />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                <Typography>Maturidade inicial: <b>1 (Verde)</b></Typography><br/>
                                                <Typography>Maturidade atual: <b>4 (Madura)</b></Typography>
                                                <Typography>Temperatura atual: <b>24˚C</b></Typography>
                                                <Typography>Luminosidade atual: <b>150</b></Typography>
                                                <Typography>Umidade atual: <b>70%</b></Typography><br/>
                                                <Typography>Tempo na fruteira: <b>7 dias</b></Typography>
                                                <Typography>Média de temperatura: <b>24˚C</b></Typography>
                                                <Typography>Média de luminosidade: <b>150</b></Typography>
                                                <Typography>Média de umidade: <b>70%</b></Typography><br/>
                                                <Typography>Tempo ainda disponível para consumo: <b>10 dias</b></Typography><br/>
                                                <Chip onClick={() => {handleOpenModalConsumir(4)}} label="Consumir fruta" className={classes.chipOutlined} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )}
                            {view === "configs" && (
                                <>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="checkedB"
                                                    color="primary"
                                                    checked={configOne}
                                                    onChange={(e) => {setConfigOne(e.target.checked);}}
                                                />
                                            }
                                            label="Alertar sobre temperatura"
                                        />
                                    </div><br/>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="checkedB"
                                                    color="primary"
                                                    checked={configTwo}
                                                    onChange={(e) => {setConfigTwo(e.target.checked);}}
                                                />
                                            }
                                            label="Alertar sobre umidade"
                                        />
                                    </div><br/>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="checkedB"
                                                    color="primary"
                                                    checked={configThree}
                                                    onChange={(e) => {setConfigThree(e.target.checked);}}
                                                />
                                            }
                                            label="Alertar sobre luminosidade"
                                        />
                                    </div>
                                    <br />
                                    <Button color="inherit" className={classes.button} onClick={handleUpdateConfigs}>
                                    {isLoadingChangeConfigs ? 
                                        <CircularProgress size={24} color="#FFF" />
                                    :
                                        'Salvar'
                                    }
                                    </Button>
                                </>
                            )}
                            {view === "info" && (
                                <>
                                    <Typography>Número de série: <b>{serailNumber}</b></Typography><br/>
                                    <a onClick={() => {setOpenPassword(true)}} style={{ textDecoration: 'none', color: '#ffc107', cursor: 'pointer' }}>atualizar senha</a>
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openConsumir}
                onClose={() => {
                    setOpenConsumir(false)
                    setSpaceConsumir('')
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openConsumir}>
                    <div className={classes.paperPassword}>
                        <Typography>Tem certeza que deseja consumir esta fruta do espaço {spaceConsumir}?</Typography>
                        <br/>
                        <Button color="inherit" className={classes.button} fullWidth>
                            Sim
                        </Button>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPassword}
                onClose={() => {setOpenPassword(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openPassword} style={{ outline: 'none' }}>
                    <div className={classes.paperPassword}>
                        {/*<TextField
                            label="Senha atual"
                            variant="outlined"
                            value={actualPassword}
                            onChange={(e) => {
                                setActualPassword(e.target.value)
                            }}
                            fullWidth
                            type="password"
                        />
                        <br/><br/>*/}
                        <TextField
                            label="Nova senha"
                            variant="outlined"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}
                            fullWidth
                            type="password"
                        />
                        <br/><br/>
                        <Button onClick={handleChangePassword} color="inherit" className={classes.button} /*disabled={!actualPassword || !newPassword}*/ fullWidth>
                            {isLoadingChangePassword ? 
                                <CircularProgress size={24} color="#FFF" />
                            :
                                'Salvar'
                            }
                        </Button>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAlert}>
                    <div className={classes.paper}>

                        <Container>
                            <Grid spacing={3} container>
                                <Grid item md={12} xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle>Alerta</AlertTitle>
                                    Suas frutas estão em perigo. Verifica as condições da sua fruteira.
                                </Alert>
                                </Grid>
                            </Grid>
                        </Container>

                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => {
                    setOpen(false)
                    setSpaceFruit('')
                    setTypeFruit('')
                    setMaturationFruit('1')
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography variant="h6">1. Escolha o espaço</Typography>
                        <br/>
                        <TextField
                            select
                            label="Espaço"
                            variant="outlined"
                            SelectProps={{
                                native: true,
                            }}
                            style={{ width: '30%' }}
                            value={spaceFruit}
                            onChange={(e) => {
                                setSpaceFruit(e.target.value)
                            }}
                        >
                            <option></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </TextField>
                        {spaceFruit && (
                            <>
                                <br/><br/>
                                <Typography variant="h6">2. Escolha o tipo de fruta</Typography>
                                <br/>
                                <TextField
                                    select
                                    label="Fruta"
                                    variant="outlined"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    style={{ width: '30%' }}
                                    value={typeFruit}
                                    onChange={(e) => {
                                        setTypeFruit(e.target.value)
                                        setMaturationFruit('1')
                                    }}
                                >
                                    <option></option>
                                    <option value="banana">Banana</option>
                                    <option value="maca">Maça</option>
                                    <option value="manga">Manga</option>
                                    <option value="tomate">Tomate</option>
                                </TextField>
                            </>
                        )}
                        {typeFruit && typeFruit === "tomate" && (
                            <>
                                <br/><br/>
                                <Typography variant="h6">3. Escolha a maturação atual da fruta</Typography>
                                <br/>
                                <RadioGroup defaultValue="1" aria-label="maturation" name="maturation">
                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={tomateStage1}
                                                        title="Tomate Stage 1"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 1"
                                                            labelPlacement="Estágio 1"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={tomateStage2}
                                                        title="Tomate Stage 2"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="2"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 2"
                                                            labelPlacement="Estágio 2"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={tomateStage3}
                                                        title="Tomate Stage 3"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="3"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 3"
                                                            labelPlacement="Estágio 3"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={tomateStage4}
                                                        title="Tomate Stage 4"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="4"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 4"
                                                            labelPlacement="Estágio 4"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={tomateStage5}
                                                        title="Tomate Stage 5"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="5"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 5 - Madura"
                                                            labelPlacement="Estágio 5 - Madura"
                                                            disabled
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </>
                        )}
                        {typeFruit && typeFruit === "banana" && (
                            <>
                                <br/><br/>
                                <Typography variant="h6">3. Escolha a maturação atual da fruta</Typography>
                                <br/>
                                <RadioGroup defaultValue="1" aria-label="maturation" name="maturation">
                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={bananaStage1}
                                                        title="Banana Stage 1"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 1"
                                                            labelPlacement="Estágio 1"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={bananaStage3}
                                                        title="Banana Stage 2"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="2"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 2"
                                                            labelPlacement="Estágio 2"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={bananaStage4}
                                                        title="Banana Stage 3"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="3"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 3"
                                                            labelPlacement="Estágio 3"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={bananaStage5}
                                                        title="Banana Stage 4"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="4"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 4"
                                                            labelPlacement="Estágio 4"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={bananaStage7}
                                                        title="Banana Stage 5"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="5"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 5 - Madura"
                                                            labelPlacement="Estágio 5 - Madura"
                                                            disabled
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </>
                        )}
                        {typeFruit && typeFruit === "manga" && (
                            <>
                                <br/><br/>
                                <Typography variant="h6">3. Escolha a maturação atual da fruta</Typography>
                                <br/>
                                <RadioGroup defaultValue="1" aria-label="maturation" name="maturation">
                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={mangaStage1}
                                                        title="Manga Stage 1"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 1"
                                                            labelPlacement="Estágio 1"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={mangaStage2}
                                                        title="Manga Stage 2"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="2"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 2"
                                                            labelPlacement="Estágio 2"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={mangaStage3}
                                                        title="Manga Stage 3"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="3"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 3"
                                                            labelPlacement="Estágio 3"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={mangaStage4}
                                                        title="Manga Stage 4"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="4"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 4"
                                                            labelPlacement="Estágio 4"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={mangaStage5}
                                                        title="Manga Stage 5"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="5"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 5 - Madura"
                                                            labelPlacement="Estágio 5 - Madura"
                                                            disabled
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </>
                        )}
                        {typeFruit && typeFruit === "maca" && (
                            <>
                                <br/><br/>
                                <Typography variant="h6">3. Escolha a maturação atual da fruta</Typography>
                                <br/>
                                <RadioGroup defaultValue="1" aria-label="maturation" name="maturation">
                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={macaStage1}
                                                        title="Maça Stage 1"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 1"
                                                            labelPlacement="Estágio 1"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={macaStage2}
                                                        title="Maça Stage 2"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="2"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 2"
                                                            labelPlacement="Estágio 2"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={macaStage3}
                                                        title="Maça Stage 3"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="3"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 3"
                                                            labelPlacement="Estágio 3"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={macaStage4}
                                                        title="Maça Stage 4"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="4"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 4"
                                                            labelPlacement="Estágio 4"
                                                            onClick={(e) => {setMaturationFruit(e.target.value)}}
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Card className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={macaStage5}
                                                        title="Maça Stage 5"
                                                    />
                                                    <CardContent>
                                                        <FormControlLabel
                                                            value="5"
                                                            control={<Radio color="primary" />}
                                                            label="Estágio 5 - Madura"
                                                            labelPlacement="Estágio 5 - Madura"
                                                            disabled
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </>
                        )}
                        {typeFruit && (
                            <>
                                <br/><br/>
                                <Button color="inherit" className={classes.button} disabled={!maturationFruit}>
                                    Salvar
                                </Button>
                            </>
                        )}
                    </div>
                </Fade>
            </Modal>

        </>
    );
}

export default Admin;