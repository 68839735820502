import React, { useState, useEffect } from "react";
import Menu from '../components/menu';
import { Container, Typography, TextField, Button, CircularProgress, Snackbar, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import API from '../api';
import { Link } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#ffc107',
        color: '#FFF',
        textTransform: 'none',
        borderRadius: '8px'
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
        }
    },
    div: {
        width: '40%',
        '@media (max-width: 779px)': {
            width: '90%'
        }
    }
});

const Reserv = props => {

    const classes = useStyles();

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [severitySnackbar, setSeveritySnackbar] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
    const [isLoadingGet, setIsLoadingGet] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);
    const [successUpdated, setSuccessUpdated] = useState(false);

    const handleGetDetails = () => {

        if(!props.match.params.id){

            window.location.href="/";

        }else{

            setIsLoadingGet(true);
            
            API.get('/validation-recovery-password' , {
                params: {
                    token: (window.location.href).split('recuperar-senha/')[1]
                },
                headers: {
                    "Authorization" : ``
                } 
            })
            .then(res => {
                setIsLoadingGet(false);
            })
            .catch(err => {
                setIsLoadingGet(false);
                setInvalidToken(true);
            })

        }

    }

    useEffect(() => {
        handleGetDetails();
    }, [])

    const handleUpdatePassword = () => {

        setIsLoading(true)

        if(password != confirmPassword){

            setIsLoading(false)
            setSeveritySnackbar('error')
            setMessageSnackbar('Senhas não são iguais')
            setOpenSnackbar(true)

        }else{

            API.post('/new-password-recovery' , { 
                password: password,
                token: (window.location.href).split('recuperar-senha/')[1]
            })
            .then(res => {
                setIsLoading(false)
                setSuccessUpdated(true)
            })
            .catch(err => {
                setIsLoading(false)
            })

        }

    }

    return (
        <>

            <Menu />

            <Container style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={classes.div}>

                    {invalidToken ?
                        <>
                            <Typography variant="h5">Link inválido!</Typography>
                        </>
                    :
                        <>
                            {successUpdated ?
                                <>
                                    <Typography variant="h5">Senha alterada com sucesso!</Typography>
                                    <br/><br/>
                                    <Link to="/login" style={{ textDecoration: 'none' }}>
                                        <Button size="large" fullWidth color="inherit" className={classes.button}>Fazer login</Button>
                                    </Link>
                                </>
                            :
                                <>
                                    <Typography variant="h5">Cadastre uma nova senha</Typography>
                                    <br/><br/>
                                    <TextField 
                                        error={errorPassword} 
                                        value={password} 
                                        onChange={(e) => {
                                            setPassword(e.target.value); 
                                            setErrorPassword(false);
                                        }} 
                                        className={classes.textField} 
                                        id="outlined-basic" l
                                        label="Senha" 
                                        variant="outlined" 
                                        fullWidth 
                                        InputLabelProps={{
                                            shrink: password ? true : false,
                                        }}
                                        type="password"
                                    />
                                    <br/><br/>
                                    <TextField 
                                        error={errorConfirmPassword} 
                                        value={confirmPassword} 
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value); 
                                            setErrorConfirmPassword(false);
                                        }} 
                                        className={classes.textField} 
                                        id="outlined-basic" 
                                        label="Confirmar senha" 
                                        variant="outlined" 
                                        fullWidth 
                                        InputLabelProps={{
                                            shrink: confirmPassword ? true : false,
                                        }}
                                        type="password"
                                    />
                                    <br/><br/>
                                    <Button disabled={!password || !confirmPassword} onClick={handleUpdatePassword} size="large" fullWidth color="inherit" className={classes.button}>
                                        {isLoading ? 
                                            <CircularProgress size={24} color="#FFF" />
                                        :
                                            'Salvar'
                                        }
                                    </Button>
                                </>
                            }
                        </>
                    }
                </div>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => {setOpenSnackbar(false)}}>
                <Alert onClose={() => {setOpenSnackbar(false)}} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={isLoadingGet}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </>
    );
}

export default Reserv;